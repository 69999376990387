<template>
  <v-col :sm="sm" :xs="xs" :md="md" :lg="lg" :cols="cols">
    <v-tooltip
      top
      max-width="250"
      nudge-top="-15"
      transition="fade-transition"
      open-delay="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card tile v-bind="attrs" v-on="on">
          <v-card-text>
            <div class="text--primary force-single-line relative pr-4">
              Name: {{ user.name }}
              <v-icon
                class="absolute-right pointer"
                size="15"
                color="primary"
                @click="onEdit"
                >mdi-pencil</v-icon
              >
            </div>
            <div class="text--primary force-single-line">
              Username: {{ user.username }}
            </div>
            <div class="text--primary force-single-line">
              Created On: {{ user.createdAt | formatDateTime }}
            </div>
          </v-card-text>
        </v-card> </template
      >{{ user.name }}
    </v-tooltip>
  </v-col>
</template>

<script>
import Moment from "moment";

export default {
  props: ["lg", "md", "sm", "xs", "cols", "user", "onEdit"],
  setup() {
    return { Moment };
  }
};
</script>
