<template>
  <div style="height: 100%">
    <v-sheet
      style="height: 50px"
      class="px-6 py-2 d-flex flex-column justify-center"
    >
      <div class="d-flex justify-end">
        <v-btn tile x-small class="primary" @click="showAddUserPopup"
          >Add New User</v-btn
        >
      </div>
    </v-sheet>
    <v-container v-if="noRecordsFound" class="text-center py-10">
      No users found.
    </v-container>
    <div class="users-listing-parent" ref="scrollable" v-bar>
      <div>
        <v-container v-if="!isLoading">
          <v-row>
            <UserCard
              v-for="user in data"
              :key="user._id"
              :user="user"
              sm="6"
              xs="12"
              md="4"
              lg="4"
              cols="12"
              :onEdit="() => editUserModal(user)"
            />
          </v-row>
        </v-container>
        <SkeltonLoader v-else cardHeight="98" loaderType="table-cell, text" />
      </div>
    </div>
    <v-dialog v-model="detailsDialog" width="600">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark class="mb-4">
            <span>{{ editMode ? "Edit User" : "Add New User" }}</span>
          </v-toolbar>

          <v-form
            autocomplete="off"
            ref="newUserForm"
            v-model="formValid"
            lazy-validation
          >
            <input
              type="email"
              name="user_name"
              id="user_name"
              value=""
              class="opacity-hide"
            />
            <input
              type="password"
              name="password"
              id="password"
              value=""
              class="opacity-hide"
            />

            <v-card-text class="pb-0"> Name </v-card-text>
            <v-card-title class="py-0">
              <v-text-field
                autocomplete="off"
                v-model="newUser.name"
                :rules="[
                  (v) =>
                    v && v.trim().length && v.trim().length <= 50
                      ? true
                      : 'Required (Max: 50 chars)'
                ]"
                class="py-0 my-0"
              ></v-text-field>
            </v-card-title>

            <v-card-text class="pb-0"> Username </v-card-text>
            <v-card-title class="py-0">
              <v-text-field
                autocomplete="off"
                :disabled="editMode"
                v-model="newUser.username"
                :rules="[
                  unValidation,
                  (u) => (u.length <= 20 ? true : 'Max: 20 characters')
                ]"
                class="py-0 my-0"
              ></v-text-field>
            </v-card-title>
            <template v-if="!editMode || (editMode && editPassword)">
              <v-card-text class="pb-0">
                Password
                <v-btn
                  v-if="editPassword"
                  tile
                  x-small
                  class="primary float-right"
                  @click="editPassword = false"
                  >Cancel Change Password</v-btn
                >
              </v-card-text>
              <v-card-title class="py-0">
                <v-text-field
                  autocomplete="off"
                  type="password"
                  v-model="newUser.password"
                  :rules="[passwordValidation]"
                  class="py-0 my-0"
                ></v-text-field>
              </v-card-title>
            </template>

            <v-card-text v-else class="pb-0">
              <v-btn tile x-small class="primary" @click="editPassword = true"
                >Change Password</v-btn
              >
            </v-card-text>

            <v-card-text class="pb-0"> Permissions </v-card-text>
            <v-card-title class="py-0 pt-2 permissions">
              <v-checkbox
                v-model="newUser.mustReturnByMe"
                dense
                hide-details
                class="ma-0 mb-2 pa-0 pr-3"
                label="Book distributions cannot be returned by other users (except admin)"
              ></v-checkbox>
            </v-card-title>

            <v-card-title class="py-0 permissions">
              <v-checkbox
                v-model="newUser.returnRestricted"
                dense
                hide-details
                class="ma-0 mb-2 pa-0 pr-3"
                label="Restrict returning books distributed by other users"
              ></v-checkbox>
            </v-card-title>

            <v-card-text class="pb-0"> Permissions </v-card-text>
            <v-card-title class="py-2 permissions">
              <v-checkbox
                v-for="(scope, i) in scopes"
                :key="`scope-${i}`"
                :input-value="newUser.scopes.includes(scope.id)"
                dense
                hide-details
                class="ma-0 mb-2 pa-0 pr-3"
                :label="scope.label"
                @change="(value) => scopeChange(value, scope.id)"
              ></v-checkbox>
            </v-card-title>
          </v-form>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog.value = false">
              Cancel
            </v-btn>
            <v-btn tile class="primary" text @click="saveNewUser">Save</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import UserCard from "@/components/UserCard.vue";
import SkeltonLoader from "@/components/SkeltonLoader.vue";
import { useUsers } from "@/modules";

export default {
  setup: useUsers,
  components: {
    UserCard,
    SkeltonLoader
  }
};
</script>

<style scoped lang="scss">
.permissions {
  ::v-deep .v-input--selection-controls__input {
    margin-right: 4px;
  }
}
</style>
